// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-equipment-js": () => import("./../../../src/pages/equipment.js" /* webpackChunkName: "component---src-pages-equipment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-chef-post-js": () => import("./../../../src/templates/chef-post.js" /* webpackChunkName: "component---src-templates-chef-post-js" */),
  "component---src-templates-equipment-post-js": () => import("./../../../src/templates/equipment-post.js" /* webpackChunkName: "component---src-templates-equipment-post-js" */),
  "component---src-templates-ingredient-post-js": () => import("./../../../src/templates/ingredient-post.js" /* webpackChunkName: "component---src-templates-ingredient-post-js" */),
  "component---src-templates-recipe-post-js": () => import("./../../../src/templates/recipe-post.js" /* webpackChunkName: "component---src-templates-recipe-post-js" */)
}

